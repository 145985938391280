$correct: #090;
$misplaced: #cc0;
$wrong: lightgray;
$darkgrey: #666;
$failed: #900;

html,
body {
  min-height: 100vh;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  min-height: 100vh;
}

.App-header {
  background-color: $failed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  font-family: "Mountains of Christmas", cursive;

  .buffer {
    width: 36px;
    flex: 0 0 auto;
  }
  h1 {
    flex: 1 1 auto;
    display: inline-block;
  }

  nav {
    margin: 0 10px;
    flex: 0 0 auto;
    justify-self: flex-end;

    button {
      background: none;
      border: none;
      margin: 0 2px;
      font-size: 1.5rem;
      color: white;
      cursor: pointer;

      &:hover {
        color: $darkgrey;
      }
    }
  }
}

.guesses {
  position: relative;
  margin-top: 10px;
}

h2 {
  position: absolute;
  align-items: center;
  justify-content: center;
  margin: -25px 0 0 -150px;
  top: 50%;
  left: 50%;
  background: white;
  z-index: 100;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px;
  width: 300px;
  border: 2px solid;
  color: white;
  display: none;

  &.success,
  &.failed {
    display: flex;
  }

  &.success {
    background-color: $correct;
    border-color: darken($correct, 10);
  }

  &.failed {
    background-color: $failed;
    border-color: darken($failed, 10);
  }
  height: 50px;
}

.guess {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  margin: 4px auto;
}

.input-tile {
  display: block;
  margin: 0 2px;

  width: 60px;
  height: 60px;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
  text-transform: uppercase;
  backface-visibility: visible !important;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }

  .tile-inner {
    border: 1px solid darkgray;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    animation-duration: 500ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  &.filled {
    border-color: black;
  }

  &.correct {
    .tile-inner {
      animation-name: flipCorrect;
    }
  }

  &.wrongplace {
    .tile-inner {
      animation-name: flipMisplaced;
    }
  }

  &.wrong {
    .tile-inner {
      animation-name: flipWrong;
    }
  }
}

.guess {
  &.correct {
    .tile-inner {
      animation-name: bounce;
      animation-duration: 1s;
    }
  }
}

@keyframes flipCorrect {
  0% {
    transform: scaleY(1);
  }

  50% {
    background: white;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
    background: $correct;
    color: white;
  }
}

@keyframes flipMisplaced {
  0% {
    transform: scaleY(1);
  }

  50% {
    background: white;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
    background: $misplaced;
    color: white;
  }
}

@keyframes flipWrong {
  0% {
    transform: scaleY(1);
  }

  50% {
    background: white;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
    background: $wrong;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    background-color: $correct;
    color: white;
  }
}

.keyboard {
  margin: 10px 0;
  max-width: 100%;

  .keyboard-row {
    display: flex;
    justify-content: center;
    align-items: stretch;

    .keyboard-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border: 1px solid $darkgrey;
      background-color: lightgray;
      color: $darkgrey;
      border-radius: 3px;
      margin: 2px;
      font-size: 2rem;
      font-weight: 600;
      min-width: 51px;
      text-transform: uppercase;

      &.enter {
        font-size: 1.2rem;
      }

      &.correct {
        background-color: $correct;
        border-color: $correct;
        color: white;
      }

      &.wrongplace {
        background-color: $misplaced;
        border-color: $misplaced;
        color: white;
      }

      &.wrong {
        background-color: $darkgrey;
        color: white;
      }
    }
  }
}

.modal-background {
  z-index:150;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.modal {
  z-index:200;
  position: absolute;
  background-color: white;
  border: 2px solid $darkgrey;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -200px;

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    z-index: 201px;
    cursor: pointer;

    &:hover {
      color: lightgray;
    }
  }
}

.stats-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .stats-counts {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;

    .stat-box {
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60px;

      label {
        font-size: 0.75rem;
        line-height: 0.8rem;
      }

      span {
        font-size: 2rem;
        display: inline-block;
      }
    }
  }

  .guess-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
    position: relative;
    margin-bottom: 2px;

    label {
      flex: 0 0 auto;
      width: 20px;
      text-align: right;
      margin-right: 5px;
    }

    .guess-bar {
      text-align: right;
      background-color: darkgray;
      flex: 0 0 auto;
      padding: 0 5px;

      &.current {
        background-color: $correct;
        color:white;
      }
    }
  }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .App {
    font-size: 14px;
  }

  .keyboard {
    .keyboard-row {
      .keyboard-button {
        font-size: 1.2rem;
        min-width: 20px;
        padding: 7.5px;

        &.enter {
          font-size: 0.9rem;
        }
      }
    }
  }

  .modal {
    width: 350px;
    margin-left: -175px;
  }
}
